<template>
  <div>
    <b-card no-body>
      <b-overlay
        :show="loading"
        variant="white"
        spinner-variant="primary"
        rounded="sm"
      >
        <b-row class="mx-2 mt-2" align-h="between" align-v="center">
          <b-col v-if="folderInfo.name" class="d-flex" md="12" lg="4">
            <div class="d-flex">
              <div>
                <FeatherIcon icon="FolderIcon" class="mr-1" size="30" />
              </div>
              <h2>
                {{ folderInfo.name }}
              </h2>
            </div>
          </b-col>
          <b-col
            md="12"
            lg="8"
            class="d-flex justify-content-end px-0"
            :class="{ 'pt-2': ['xs', 'sm'].includes(breakpoint) }"
          >
            <b-button
              @click="$bvModal.show('shared')"
              variant="outline-primary"
              class="btn d-flex align-items-center mr-1"
            >
              <feather-icon icon="Share2Icon" size="20" />
              <span class="ml-50"> {{ $t("Compartir carpeta") }} </span>
            </b-button>
            <b-button
              @click="downloadZipAllFiles"
              variant="outline-primary"
              class="btn d-flex align-items-center"
              :disabled="folders.length === 0"
            >
              <span class="mr-50"> {{ $t("Descargar archivos") }} </span>
              <feather-icon icon="DownloadIcon" size="20"/>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr />
          </b-col>
          <b-col cols="12">
            <UploadFoldersDropzone
              :key="`${dropzoneKey}`"
              @uploadFolders="handleFolders"
              @uploadFiles="handleFiles"
            ></UploadFoldersDropzone>
          </b-col>
          <b-col
            v-if="
              folders.length &&
              folders[0].childs.length === 0 &&
              folders[0].files.length === 0
            "
            cols="12"
            class="d-flex justify-content-center"
            style="height: 200px"
          >
            <h3 class="mt-5 text-secondary">
              <FolderOpen
                class="mr-50"
                style="height: 25px; width: 25px"
              ></FolderOpen>
              {{ $t("Carpeta vacia") }}
            </h3>
          </b-col>
          <b-col
            v-else
            class="p-2"
            :class="{ 'mx-2': !['xs', 'sm'].includes(breakpoint) }"
          >
            <div style="min-height: 150px">
              <TreeFoldersComponent
                v-for="(folder, index) in folders"
                :key="index"
                :folder="folder"
                @downloadFile="downloadFileHandler"
              />
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </b-card>
    <SharedModal :folderHash="folderInfo.hash"></SharedModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UploadFoldersDropzone from "@/components/folders/dropzone/UploadFoldersDropzone.vue";
import TreeFoldersComponent from "@/components/folders/tree/TreeFoldersComponent.vue";
import FolderOpen from "@/assets/icons/FolderOpen.vue";
import SharedModal from "@/components/shared/modal/SharedModal.vue";
import folders from "@/store/folders";

export default {
  components: {
    UploadFoldersDropzone,
    TreeFoldersComponent,
    FolderOpen,
    SharedModal,
  },
  data() {
    return {
      loading: false,
      folderInfo: {
        hash: "",
      },
      folders: [],
      files: [],
      dropzoneKey: new Date(),
    };
  },
  computed: {
    folderId() {
      return this.$route.params.id;
    },
    breakpoint() {
      return this.$store.getters["app/currentBreakPoint"];
    },
  },
  methods: {
    ...mapActions({
      getFolder: "folders/getFolder",
      uploadFolders: "folders/createStructure",
      uploadFiles: "archives/save",
      downloadFile: "archives/downloadArchive",
      downloadAllFiles: "archives/downloadArchivesZip",
    }),
    reloadDropzoneComponent() {
      this.dropzoneKey = new Date();
    },
    async handleFolders(folders) {
      if (folders.length === 1) {
        if (this.folders[0] && this.folders[0].childs.length > 0) {
          this.$bvModal
            .msgBoxConfirm(
                this.$t('¿Estás seguro de cargar esta carpeta, los datos actuales se eliminaran?'),
              {
                bodyClass: "bodyDelete",
                footerClass: "footerDelete",
                okVariant: "danger",
                okTitle: this.$t('Continuar'),
                cancelTitle: this.$t('Cancelar'),
                cancelVariant: "outline-danger",
                centered: true,
              }
            )
            .then(async (value) => {
              if (value === true) {
                this.uploadData(folders);
              } else {
                this.reloadDropzoneComponent();
              }
            });
        } else {
          this.uploadData(folders);
        }
      } else {
        this.$store.commit("notifications", {
          title: this.$t("Carga solo una carpeta con toda tu estructura."),
          variant: "danger",
        });
        this.$store.commit("setShowNotifications", true, { root: true });
      }
    },
    async uploadData(folders) {
      this.loading = true;
      console.log(folders)
      console.log("mierda")
      console.log("SSSSSS")

      try {
        await this.uploadFolders({
          folder_id: this.folderId,
          structure: folders,
        });
        await Promise.all(
          this.files.map(async (file) => {
            const fileFormData = new FormData();
            fileFormData.append("folder_id", this.folderId);
            fileFormData.append("file", file.file);
            fileFormData.append("path", file.path);
            await this.uploadFiles(fileFormData);
          })
        );
        await this.loadData();
      } finally {
        this.reloadDropzoneComponent();
        this.loading = false;
        this.$store.commit("notifications", {
          title: this.$t("Carpeta creada con éxito."),
          variant: "success",
        });
        this.$store.commit("setShowNotifications", true, { root: true });
      }
    },
    handleFiles(files) {
      this.files = files;
    },
    async downloadZipAllFiles() {
      this.loading = true;
      await this.downloadAllFiles({
        name: this.folderInfo.name,
        folder_id: this.folderId,
      });
      this.loading = false;
    },
    async downloadFileHandler(archive, action) {
      this.loading = true;
      await this.downloadFile({ archive, folder_id: this.folderId, action });
      this.loading = false;
    },
    async loadData() {
      const response = await this.getFolder(this.folderId);
      const { name, hash, childs } = response.data[0];

      this.folderInfo = { name, hash };
      console.log(childs);
      this.folders = this.orderArray(childs);
    },
    orderArray(elements) {
      elements.sort((a, b) => a.name.localeCompare(b.name));

      elements.forEach((item) => {
        if (item.childs && item.childs.length > 0) {
          this.orderArray(item.childs);
        }
        if (item.files && item.files.length > 0) {
          this.orderArray(item.files);
        }
      });

      return elements;
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style scoped>
.category-tree-node:hover {
  background-color: #f3f2f7;
}

.category-tree-node-name {
  padding-left: 35px;
}
</style>
