<template>
  <div :style="`background: #1A1B22`">
    <b-row class="no-gutters">
      <b-col
        cols="12"
        xl="8"
        lg="7"
        class="d-lg-block d-none"
      >
        <div
          class="background-custom d-flex justify-content-center align-items-center"
        >
          <b-img
            class="login-logo"
            :src="appLogoImage"
            alt="logo"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        xl="4"
        lg="5"
      >
        <div
          class="min-vh-100 pt-3 pb-5 bg-white h-100 d-flex justify-content-center align-items-center"
        >
          <div style="width: 100%; padding: 0 40px">
            <img
              :src="appLogoImage"
              alt="GH Induction"
              class="d-lg-none d-block p-md-3 pb-2"
              width="100%"
            >
            <b-col class="d-flex justify-content-between align-items-center pr-0 pl-0" cols="12">
              <span>
                <h2>{{ $t("Carpeta") }}:</h2>
                <h2>{{ folderName }}</h2>
              </span>
              <ul class="list-unstyled mb-0">
                <locale />
              </ul>
            </b-col>
            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="handleSubmit"
              >
                <!-- forgot password -->
                <b-form-group>
                  <label for="login-password">{{ $t("Contraseña") }}</label>

                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      ref="errorField"
                      class="text-danger"
                    >{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  class="btn-block"
                >
                  {{ $t("Acceder") }}
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="btn-block d-flex justify-content-center align-items-center mt-2"
                  @click="$bvModal.show('shared')"
                >
                  <feather-icon
                    icon="Share2Icon"
                    size="18"
                  />
                  <span class="pl-50">
                    {{ $t("Compartir carpeta") }}
                  </span>
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-container
      class="auth-wrapper-3"
      hidden
    >
      <b-row
        class="justify-content-center middle"
        align-v="center"
      >
        <b-col
          lg="10"
          class="d-flex auth-bg px-2 p-lg-5 background-white"
        >
          <b-col
            sm="12"
            md="6"
            lg="6"
            class="px-xl-2"
          >
            <b-card-title
              title-tag="h1"
              class="font-weight-bolder mb-1 text-white"
            >
              {{ $t("Acceder") }}
            </b-card-title>
            <div style="width: 25%">
              <hr class="linea">
            </div>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            class="px-xl-2 mx-auto"
          />
        </b-col>
      </b-row>
    </b-container>
    <SharedModal :folder-hash="folderHash" />
    <app-footer class="mx-1" />
  </div>
</template>

<script>
import AppFooter from '@core/layouts/components/AppFooter.vue'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { mapActions, mapMutations } from 'vuex'
import SharedModal from '@/components/shared/modal/SharedModal.vue'
import { getIp } from '@/shared/services/ip-service'
import { $themeConfig } from '../../../themeConfig'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'

export default {
  components: {
    Locale,
    ValidationProvider,
    ValidationObserver,
    AppFooter,
    SharedModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      ip_address: '',
      folderName: '',
      password: '',
      sideImg: require('@/assets/images/pages/login/hall.jpg'),
      required,
      showForgotPassword: false,
      recoverButtonTimeout: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/login/login.png')
        this.sideImg = require('@/assets/images/pages/login/background.png')
        return this.sideImg
      }
      return this.sideImg
    },
    folderHash() {
      return this.$route.params.hash
    },
  },
  methods: {
    ...mapActions({
      getName: 'folders/getFolderName',
      access: 'folders/accessFolder',
    }),
    ...mapMutations({
      setCurrentLanguage: 'languages/setCurrentLanguage',
      loading: 'app/loading',
    }),
    handleSubmit() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          const { password } = this
          if (password) {
            const { ip_address, folderHash } = this
            this.access({ hash: folderHash, password, ip_address })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          })
        }
      })
    },
    async loadData() {
      this.loading(true, { root: true })
      const ip = await getIp()
      const response = await this.getName({ hash: this.folderHash, ip })
      this.folderName = response.data
      this.ip_address = ip
    },
  },
  async mounted() {
    await this.loadData()
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
