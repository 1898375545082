import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import i18n from '@/libs/i18n'

export default {
  appendEntry(formData, entry, label) {
    if (!entry || entry === '') {
      return
    }

    if (entry instanceof Object) {
      Object.keys(entry).forEach(key => formData.append(`${label}[${key}]`, entry[key]))
    } else {
      formData.append(label, entry)
    }
  },
  reloadFormValidationMessages() {
    Object.keys(rules).forEach(rule => {
      extend(rule, {
        ...rules[rule],
        message: i18n.t(`validations.${rule}`, {
          name: '{_field_}',
          max: '{max}',
          min: '{min}',
          width: '{width}',
          length: '{length}',
          height: '{height}',
          size: '{size}',
        }),
      })
    })
  },
}
