<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
        <b-row
            align-h="between"
            align-v="center"
            class="px-1 pt-2 pb-1"
        >
          <b-col cols="auto">
            <h3 class="m-0">
              {{ $t('Cambiar contraseña') }}
            </h3>
          </b-col>
        </b-row>
        <hr>
        <b-row
            align-h="between"
            align-v="center"
            no-gutters
            class="px-2 pt-1 pb-2"
        >
          <b-col cols="12">
            <validation-observer
                ref="forgotPasswordReset"
            >
              <b-form
                  @submit.prevent="handleSubmitResetPassword"
              >
                <b-row>
                  <!-- old password -->
                  <b-col md="6">
                    <b-form-group
                        :label="$t('Contraseña actual')"
                        :label-for="$t('Contraseña actual')"
                    >
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('Contraseña actual')"
                          rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                              id="account-old-password"
                              v-model="passwordValueOld"
                              name="old-password"
                              :type="passwordFieldTypeOld"
                              placeholder="xxxxxxxxxx"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                                :icon="passwordToggleIconOld"
                                class="cursor-pointer"
                                @click="togglePasswordOld"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--/ old password -->
                </b-row>
                <b-row>
                  <!-- new password -->
                  <b-col md="6">
                    <b-form-group
                        label-for="account-new-password"
                        :label="$t('Nueva contraseña')"
                    >
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('Nueva contraseña')"
                          rules="required|min:8"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                              id="account-new-password"
                              v-model="newPasswordValue"
                              :type="passwordFieldTypeNew"
                              name="new-password"
                              placeholder="xxxxxxxxxx"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                                :icon="passwordToggleIconNew"
                                class="cursor-pointer"
                                @click="togglePasswordNew"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--/ new password -->

                  <!-- retype password -->
                  <b-col md="6">
                    <b-form-group
                        label-for="account-retype-new-password"
                        :label="$t('Repetir nueva contraseña')"
                    >
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('Repetir nueva contraseña')"
                          rules="required|min:8"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                              id="account-retype-new-password"
                              v-model="RetypePassword"
                              :type="passwordFieldTypeRetype"
                              name="retype-password"
                              placeholder="xxxxxxxxx"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                                :icon="passwordToggleIconRetype"
                                class="cursor-pointer"
                                @click="togglePasswordRetype"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                        <div>
                          <small class="text-danger">{{ passwordsDontMatchFormError }}</small>
                        </div>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--/ retype password -->

                  <!-- buttons -->
                  <b-col cols="12" class="text-right">
                    <b-button
                        variant="primary"
                        class="mt-1 mr-1"
                        type="submit"
                    >
                      {{ $t('Enviar') }}
                    </b-button>
                  </b-col>
                  <!--/ buttons -->
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>

        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/libs/i18n'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getUser',
    }),
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordsDontMatchFormError() {
      if (this.newPasswordValue !== this.RetypePassword) {
        return i18n.t('Las contraseñas no coinciden')
      }

      return ''
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    ...mapActions({
      resetPassword: 'users/resetPassword',
    }),
    handleSubmitResetPassword() {
      this.$refs.forgotPasswordReset.validate().then(success => {
        if (!success || this.newPasswordValue !== this.RetypePassword) {
          return
        }

        this.resetPassword({
          id: this.currentUser.id,
          oldPassword: this.passwordValueOld,
          newPassword: this.newPasswordValue,
        })
      })
    },
  },
}
</script>
