import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVue } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import router from './router'
import store from './store'
import App from './App.vue'
import i18n from '@/libs/i18n'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/flatpickr'

Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

// Interceptor para agregar el token a las llamadas al API
import '@/api/interceptors/JwtToken'

//Complements
import './libs/veevalidate'

store.dispatch('languages/getLanguages')
store.commit('languages/recoverCurrentLanguage')
const lang = store.getters['languages/getCurrentLanguage']

i18n.locale = lang
i18n.fallbackLocale = lang

Vue.config.devtools = true;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
