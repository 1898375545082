<template>
  <div class="tree-node">
    <div
        class="d-flex align-middle text-secondary node-label my-50"
        @click="toggle"
    >
      <FeatherIcon
          :icon="isOpen ? 'ChevronDownIcon' : 'ChevronRightIcon'"
          size="25"
          class="pl-50"
      />
      <div>
        <FolderOpen
            v-if="isOpen"
            class="mx-50"
            style="height: 22px; width: 22px"
        ></FolderOpen>
        <FeatherIcon v-else icon="FolderIcon" class="mx-50" size="20" />
      </div>
      <h3 class="mb-0">{{ folder.name }}</h3>
    </div>
    <div v-if="isOpen" class="children">
      <TreeFoldersComponent
          v-for="(child, index) in folder.childs"
          :key="index"
          :folder="child"
          :download="download"
      @downloadFile="downloadFile"
      class="child-node"
      />
    </div>
    <div v-if="isOpen" class="children">
      <div
          v-for="(file, index) in folder.files"
          :key="index"
          class="mx-1 my-50 child-node"
      >
        <div class="d-flex">
          <div>
            <FeatherIcon icon="FileIcon" size="22" class="mx-1" />
          </div>
          <h3
              @click="file.type === 'application/pdf' ? downloadFile(file, 'view') : ''"
              :class="{ link: file.type === 'application/pdf' }"
              class="text-truncate"
          >
            {{ file.name }}
          </h3>
          <!-- Mostrar DownloadIcon solo si download es 1 -->
          <div v-if="download === 1" @click="downloadFile(file, 'download')">
            <FeatherIcon
                icon="DownloadIcon"
                size="17"
                class="ml-1"
                style="margin-top: 3px"
            />
          </div>
          <!-- Mostrar ExternalLinkIcon solo si file.type es PDF y download es 1 -->
          <div v-if="file.type === 'application/pdf'" @click="downloadFile(file, 'view')">
            <FeatherIcon
                icon="ExternalLinkIcon"
                size="17"
                style="margin-top: 3px"
                class="ml-1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FolderOpen from "@/assets/icons/FolderOpen.vue";

export default {
  name: "TreeFoldersComponent",
  props: {
    folder: {
      type: Object,
      required: true,
    },
    download: {
      type: Number,
      default: 1, // Por defecto, download es 1 (activo)
    },
  },
  components: {
    FolderOpen,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    downloadFile(file, action) {
      this.$emit("downloadFile", file, action);
    },
  },
};
</script>

<style scoped>
.tree-node {
  padding: 5px 0;
  cursor: pointer;
  position: relative;
}

.node-label {
  position: relative;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.child-node {
  position: relative;
  padding-left: 20px;
}

.child-node::before,
.node-label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 100%;
  border-left: 1px solid #ccc;
}

.children {
  padding-left: 20px;
  border-left: 1px solid #ccc;
}

.children > .child-node:last-child::before {
  height: 100%; /* Corta la línea en el último hijo */
}
</style>
