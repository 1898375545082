import archivesApi from '@/api/archives-api'
import DownloadService from '@/shared/services/download-service'
import router from '@/router'
import i18n from '@/libs/i18n'

const handleDownload = async (commit, apiCall, archive, action, name, zip = false) => {
  try {
    const response = await apiCall
    // Determina el nombre de archivo, agregando la extensión .zip si zip es verdadero
    let filename = name || archive?.name || DownloadService.getFilenameFromResponse(response)

    if (zip) {
      // Forzar extensión .zip si zip es true
      filename = filename.endsWith('.zip') ? filename : `${filename}.zip`
    }

    if (action === 'download') {
      DownloadService.resolveAndDownloadBlob(new Blob([response.data]), filename)
    } else if (action === 'view') {
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const url = URL.createObjectURL(blob)
      window.open(url, '_blank')
    }
  } catch (error) {
    handleErrors(commit, error)
  } finally {
    commit('setShowNotifications', true, { root: true })
  }
}


const handleErrors = async (commit, error) => {
  if (error.response?.status === 401 || error.response?.status === 403) {
    const { hash } = JSON.parse(localStorage.getItem('token'))
    await router.push({ name: 'carpetas', params: { hash } })
    commit(
      'notifications',
      { title: i18n.t('El acceso no es válido. Ingresa la contraseña nuevamente.'), variant: 'danger' },
      { root: true },
    )
    dispatch('removeAccessFolder')
  } else {
    commit(
      'notifications',
      { title: i18n.t('Error al descargar el archivo.'), variant: 'danger' },
      { root: true },
    )
  }
}

const actions = {
  async save({ commit }, archive) {
    try {
      await archivesApi.save(archive)
    } catch (err) {
      commit(
        'notifications',
        { title: i18n.t('Error al subir archivos.'), variant: 'danger' },
        { root: true },
      )
    } finally {
      commit('setShowNotifications', true, { root: true })
    }
  },

  async downloadArchive({ commit }, { folder_id, archive, action }) {
    await handleDownload(commit, archivesApi.download(folder_id, archive.id), archive, action)
  },

  async downloadPublicArchive({ commit }, { folder_id, archive, action }) {
    await handleDownload(commit, archivesApi.downloadPublic(folder_id, archive.id), archive, action)
  },

  async downloadArchivesZip({ commit }, { name, folder_id }) {
    await handleDownload(commit, archivesApi.downloadAll(folder_id), null, 'download', name, true)
  },

  async downloadPublicArchivesZip({ commit }, { name, folder_id }) {
    await handleDownload(commit, archivesApi.downloadPublicAll(folder_id), null, 'download', name, true)
  },

  async removeAccessFolder() {
    localStorage.removeItem('token')
  },
}

export default {
  namespaced: true,
  actions,
}
