<template>
  <div v-if="userPrepare">
    <!-- show card -->
    <div>
      <b-row v-if="userPrepare && !mostrarForm">
        <b-col cols="12">
          <b-card no-body>
            <b-row
                align-h="between"
                align-v="center"
                class="px-1 pt-1 pt-md-2 pb-0 pb-md-1"
            >
              <b-col cols="auto" class="py-md-0 py-1">
                <h3 class="m-0">
                  {{ $t('Mi perfil') }}
                </h3>
              </b-col>
              <b-col cols="auto">
                <b-row class="text-right align-items-center">
                  <b-col cols="auto" class="py-sm-0 py-1">
                    <b-link class="d-inline-flex align-items-center btn btn-primary mr-2" @click="toogleForm">
                      <span class="pr-1">
                        <feather-icon icon="Edit2Icon" />
                      </span>
                      <span class="">{{ $t('Editar') }}</span>
                    </b-link>
                    <b-link v-if="currentUser.id == user.id" class="d-inline-flex align-items-center btn btn-primary mr-2" @click="logout()">
                      <span class="pr-1">
                        <feather-icon icon="LogOutIcon" />
                      </span>
                      <span class="">{{ $t('Cerrar sesión') }}</span>
                    </b-link>
                  </b-col>
                </b-row>
              </b-col>

            </b-row>
            <hr>
            <b-row
                class="px-1 pt-1 pb-1">
              <b-col cols="12" lg="2" md="3">
                  <div class="pr-2">
                    <b-avatar size="6rem" :src="user.avatar" />
                  </div>
              </b-col>
              <b-col>
                <p class="pt-1">
                  <strong>{{ $t('Usuario') }}:</strong>
                  {{ user.name }} {{ user.surname }}
                </p>
                <p>
                  <strong>{{ $t('Email') }}:</strong>
                  {{ user.email }}
                </p>
              </b-col>

            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!-- user data -->
    </div>

    <!-- edit forms -->
    <div v-if="mostrarForm">
      <account-setting-general :general-data="user" />
    </div>

    <!-- password -->
    <account-setting-password />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'

export default {
  components: {
    AccountSettingGeneral,
    AccountSettingPassword,
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getUser',
      user: 'users/getUser',
    }),
    userPrepare() {
      if (Object.keys(this.user).length > 0) {
        return true
      }
      return false
    },
  },
  data() {
    return {
      options: {},
      mostrarForm: false
    }
  },
  methods: {
    ...mapActions({
      getUser: 'users/getUser',
    }),
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      // Redirect to login page
      this.$router.push({ name: 'auth-login' })

      location.reload()
    },
    toogleForm() {
      if (this.mostrarForm) {
        this.mostrarForm = false
      } else {
        this.mostrarForm = true
      }
    }
  },
  async created() {
    await this.getUser(this.currentUser.id)
  },
}
</script>
