<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <!-- header -->
          <b-row
            align-h="between"
            align-v="center"
            class="px-1 pt-2 pb-1"
          >
            <b-col cols="auto">
              <h3 class="m-0">
                {{ $t('Usuarios') }}
              </h3>
            </b-col>
            <b-col cols="auto">
              <b-link :to="{ name: 'createUser' }">
                <b-button
                  variant="primary"
                  class="mr-1 text-nowrap"
                >
                  + {{ $t("Añadir usuario") }}
                </b-button>
              </b-link>
            </b-col>
          </b-row>
          <hr class="m-0">

          <!-- filters -->
          <div class="p-1">
            <b-col
              cols="12"
              class="text-right"
            >
              <span
                class="cursor-pointer"
                @click="visibleFilter = !visibleFilter"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="20"
                />
              </span>
            </b-col>
            <b-collapse
              id="collapse"
              v-model="visibleFilter"
              class="mt-2"
            >
              <div class="px-2">
                <b-row>
                  <b-col
                    sm="4"
                    md="8"
                  >
                    <div
                      class="d-flex justify-content-start align-items-center"
                    >
                      <b-form-input
                        v-model="searchTerm"
                        :placeholder="$t('Nombre')"
                        type="text"
                        class="d-inline-block w-50"
                      />
                      <b-button
                        class="ml-1"
                        variant="primary"
                        @click="handleSearch"
                      >
                        {{ $t("Buscar") }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <!-- table -->
          <b-table
            hover
            responsive
            :items="items"
            :fields="columns"
          >
            <template #cell(fullName)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  size="md"
                  :src="data.item.avatar"
                />
                <span class="ml-1">
                  <b-link
                    :to="{ name: 'viewUser', params: { id: data.item.id } }"
                    class="link"
                  >
                    {{ data.item.name }}
                  </b-link>
                </span>
              </div>
            </template>
            <template #cell(created_at)="data">
              <span> {{ formatDate(data.item.created_at, "d/m/Y") }}</span>
            </template>
            <!-- <template #cell(status)="data">
              <b-badge :variant="statusVariant(data.item.active)">
                {{ statusVariantText(data.item.active) }}
              </b-badge>
            </template> -->
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'viewUser', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <b-link
                  v-if="currentUser.roles[0].name === 'super_admin'"
                  :to="{ name: 'editUser', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span
                  v-if="currentUser.roles[0].name === 'super_admin'"
                  class="d-inline-block px-50 text-danger"
                  @click="deleteUser(data.item.id, data.item.name)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div
            v-if="totalItems > pageLength"
            class="d-flex align-items-center justify-content-between mb-1 px-2"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ $t("Por página") }}: </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ $t("Total") }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    vSelect,
    flatPickr,
  },
  data() {
    return {
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '25', '50'],
      columns: [
        {
          label: this.$t('Nombre'),
          key: 'fullName',
        },
        {
          label: this.$t('Email'),
          key: 'email',
        },
        {
          label: this.$t('Fecha de registro'),
          key: 'created_at',
        },
        // {
        //   label: "Estado",
        //   key: "status",
        // },
        {
          label: this.$t('Acciones'),
          key: 'actions',
          class: 'column-actions',
        },
      ],
      formatDate,
      searchTerm: '',
      // status: [
      //   {
      //     1: "Current",
      //     2: "Professional",
      //     3: "Rejected",
      //     4: "Resigned",
      //     5: "Applied",
      //   },
      //   {
      //     1: "light-primary",
      //     2: "light-success",
      //     3: "light-danger",
      //     4: "light-warning",
      //     5: "light-info",
      //   },
      // ],
      visibleFilter: false,
    }
  },
  computed: {
    ...mapGetters({
      items: 'users/getItems',
      totalItems: 'users/getTotalItems',
      currentUser: 'auth/getUser',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    // statusVariant() {
    //   const statusColor = {
    //     1: "light-success",
    //     0: "light-danger",
    //     true: "light-success",
    //     false: "light-danger",
    //   };

    //   return (status) => statusColor[status];
    // },
    // statusVariantText() {
    //   const statusText = {
    //     1: "Activo",
    //     0: "Pendiente",
    //     true: "Activo",
    //     false: "Pendiente",
    //   };

    //   return (status) => statusText[status];
    // },
  },
  watch: {
    '$route.name': function (val) {
      this.chargeData()
    },
  },
  methods: {
    ...mapActions({
      list: 'users/getListUsers',
      delete: 'users/delete',
    }),
    chargeData() {
      this.$forceUpdate()

      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        roles: ['super_admin'],
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    deleteUser(id, name) {
      this.$bvModal
        .msgBoxConfirm(this.$t("EliminarConfirm", { nombre: name }), {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: this.$t('Eliminar'),
          cancelTitle: this.$t('Cancelar'),
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
  },
  async created() {
    await this.chargeData()
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.clear-button {
  position: absolute;
  top: 18%;
  right: 42px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
</style>
