<template>
  <BaseModal
    v-model="model"
    size="sm"
    centered
    :title="$t('Crear nueva carpeta')"
    :loading="loading"
    @confirm-button-click="createFolder"
  >
    <validation-observer ref="createFolder">
      <b-row>
        <b-col sm="12">
          <b-form-group
            :label="$t('Nombre de la carpeta')+'*'"
            label-for="account-name"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Nombre de la carpeta')"
              rules="required"
            >
              <b-form-input
                v-model="name"
                name="name"
                :placeholder="$t('Nombre de la carpeta')"
              />
              <small ref="errorField" class="text-danger">{{
                errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <validation-provider
            #default="{ errors }"
            :name="$t('Contraseña de la carpeta')"
            rules="required|max:6"
          >
            <b-form-group
              :label="$t('Contraseña de la carpeta')+'*'"
              label-for="account-password"
            >
              <b-input-group>
                <b-form-input
                  v-model="password"
                  :placeholder="$t('Contraseña de la carpeta')"
                  counter
                  maxLength="6"
                  outlined
                  class="w-full"
                />
              </b-input-group>
              <small class="d-flex justify-content-end mr-50 mt-50">
                {{ password.length || 0 }}/6
              </small>

              <small ref="errorField" class="text-danger">{{
                errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </BaseModal>
</template>

<script>
import { mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import BaseModal from "@/components/ui/modal/BaseModal.vue";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BaseModal,
  },
  data() {
    return {
      required,
      name: "",
      password: "",
      loading: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    model(value) {
      if (!value) {
        this.resetData();
      }
    },
  },
  methods: {
    ...mapActions({
      create: "folders/create",
    }),
    resetData() {
      this.name = "";
      this.password = "";
      this.loading = false;
    },
    async createFolder() {
      const validate = await this.$refs.createFolder.validate();

      if (!validate) {
        return;
      }

      this.loading = true;

      const { name, password } = this;

      await this.create({ name, password });

      this.$emit("created");
    },
  },
};
</script>
