<template>
  <div class="position-relative">
    <vue-dropzone 
      id="dropzone" 
      ref="myVueDropzone" 
      :options="dropzoneOptions"
      @vdropzone-removed-file="removeThisFile"
    />
    <div class="drop-icon">
      <feather-icon class="drop-icon-camera" icon="CameraIcon" size="20" hidden />
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import axios from 'axios'
import { config } from '@/shared/app.config'

export default {
  name: 'ImageDropzone',
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    files: {
      type: Array,
    },
    maxFiles: {
      type: String,
    },
  },
  data() {
    let confMaxfiles = 1
    if (this.maxFiles) {
      confMaxfiles = this.maxFiles
    }
    return {
      file: '',
      template: `
                <div class="dz-preview dz-file-preview">
                  <div class="dz-details">
                    <div class="dz-filename"><span data-dz-name></span></div>
                    <div class="dz-size" data-dz-size></div>
                    <img data-dz-thumbnail />
                  </div>
                  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                  <div class="dz-success-mark"><span>✔</span></div>
                  <div class="dz-error-mark"><span>✘</span></div>
                  <div class="dz-error-message"><span data-dz-errormessage></span></div>
                </div>
            `,
      // https://www.dropzonejs.com/#configuration-options
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        paramName: 'myFile',
        thumbnailWidth: 200,
        maxFilesize: 20,
        maxFiles: confMaxfiles,
        acceptedFiles: 'image/*,application/pdf,.psd, text/csv, .rfa, .ifc, .zip',
        dictDefaultMessage: `<div class="dz-message-default">${this.$t('ArrastraArchivos')}</div>`
          + '<div class="dz-message-image" hidden>'
          + '<svg viewBox="0 0 24 24" width="32" height="32" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>'
          + '<div class="mt-1">'
          + this.$t('Añadir foto de perfil')
          + '</div>'
          + '</div>',
        dictFileTooBig: 'Archivo demasiado grande',
        wasQueueAutoProcess: true,
        autoQueue: true,
        addRemoveLinks: true,
        dictRemoveFile: 'Eliminar',
        // previewTemplate: this.template
      },
    }
  },
  mounted() {
    const elementVue = this.$refs.myVueDropzone
    if (this.files) {
      this.files.forEach(element => {
        let thumbnail = element.url
        let type = 'image/png'

        const parts = element.url.split('.')

        switch (parts[parts.length - 1]) {
          case 'svg':
          case 'SVG':
            type = 'image/svg+xml'
            break
          case 'png':
          case 'PNG':
            type = 'image/png'
            break
          case 'jpg':
          case 'JPG':
            type = 'image/jpeg'
            break
          case 'gif':
          case 'GIF':
            type = 'image/gif'
            break
          case 'webp':
          case 'WEBP':
            type = 'image/webp'
            break
          case 'csv':
            type = 'text/csv'
            break
          case 'doc':
          case 'DOC':
            type = 'application/msword'
            thumbnail = config.placeholderDocuments
            break
          case 'pdf':
          default:
            thumbnail = config.placeholderDocuments
            break
          case 'PDF':
            type = 'application/octet-stream'
            type = 'application/pdf'
            thumbnail = config.placeholderDocuments
            break
        }
        const file = {
          name: element.name,
          type: 'image/png',
          dataURL: element.url,
          urlDelete: element.urlDelete,
          width: 150,
          height: 150,
        }
        elementVue.manuallyAddFile(file, element.url)
        elementVue.dropzone.emit('thumbnail', file, thumbnail)
        elementVue.dropzone.emit('complete', file)
      })
    }
  },
  methods: {
    hasFilesRejected() {
      return this.$refs.myVueDropzone.getRejectedFiles().length > 0
    },
    emptyDropzone() {
      const elementVue = this.$refs.myVueDropzone
      elementVue.removeAllFiles()
    },
    removeThisFile(file) {
      if (file.urlDelete) {
        const thisVs = this.$vs
        if (this.$refs.myVueDropzone.dropzone.disabled !== true) {
          axios.delete(`${config.webApiBase}${file.urlDelete}`)
            .then(() => {
              thisVs.notify({
                title: 'Imagen borrada', text: '', color: 'success', position: 'top-right',
              })
            })
            .catch(() => {
              thisVs.notify({
                title: 'No ha sido posible eliminar la imagen', text: 'Error', color: 'danger', position: 'top-right',
              })
            })
        }
      }
    },
    submitFiles() {
      // let files = this.files;
      const rejectedFiles = this.$refs.myVueDropzone.getRejectedFiles()

      if (this.hasFilesRejected()) {
        console.log('FILES REJECT', rejectedFiles)
      }

      const formData = this.getFormData()
      // 'https://httpbin.org/post'
      const thisVs = this.$vs
      axios.post(`${config.webApiBase}/app/attachment`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(() => {
          thisVs.notify({
            title: 'Imagen subida', text: ':)', color: 'success', position: 'top-right',
          })
        })
        .catch(() => {
          thisVs.notify({
            title: 'No ha sido posible subir la imagen', text: '', color: 'danger', position: 'top-right',
          })
        })
    },
    getFormData(nameOption = 'myFile', formData = new FormData()) {
      const files = this.getFiles()

      for (let i = 0; i < files.length; i++) {
        const file = files[i]

        formData.append(`${nameOption}[${i}]`, file)
      }

      return formData
    },
    getFiles() {
      return this.$refs.myVueDropzone.getAcceptedFiles()
    },
  },
}

</script>

<style>
.dropzone .dz-preview .dz-image {
  width: 200px !important;
  height: 200px !important;
}

.dropzone .dz-preview .dz-image img {
  max-width: 200px;
}

.dropzone .dz-preview .dz-details .dz-size {
  display: none !important;
}
/* 
.dropzone .dz-preview .dz-progress {
  display: none !important;
} */
</style>
