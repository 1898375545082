<template>
  <div>
    <vue-dropzone
        ref="myVueDropzone"
        id="dropzone"
        :useCustomSlot="true"
        :options="dropzoneOptions"
        @vdropzone-success="dropzoneSuccees"
        @vdropzone-sending="sendingEvent"
        @vdropzone-drag-over="drag = true"
        @vdropzone-drag-leave="drag = false"
    >
      <div :class="drag ? 'dropzone-drag-content' : 'dropzone-custom-content'">
        <div>
          <h3 class="dropzone-custom-title">{{ $t('Arrastra tu estructura de carpetas aqui!') }}</h3>
        </div>
      </div>
    </vue-dropzone>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      myFiles: [],
      drag: false,
      dropzoneOptions: {
        url: "/dummy-url",
        autoProcessQueue: false,
        thumbnailWidth: 150,
        maxFilesize: 500,
        headers: { "My-Awesome-Header": "header value" },
        clickable: false,
      },
    };
  },
  methods: {
    startDragHandler(event) {
      console.log(event);
    },
    dropzoneSuccees(file, dos) {
      // console.log(file, dos)
    },
    sendingEvent(file, xhr, formData) {
      // formData.append('paramName', 'some value or other');
    },
  },
  async mounted() {
    const dropzoneElement = document.getElementById("dropzone");

    dropzoneElement.addEventListener("drop", (event) => {
      event.preventDefault();
      handleDrop(Array.from(event.dataTransfer.items));
    });

    const root = [];
    const filesArray = [];

    const handleDrop = async (items) => {
      root.length = 0;
      filesArray.length = 0;

      for (const item of items) {
        const entry = item.webkitGetAsEntry();
        if (entry) {
          await traverseFileTree(entry);
        }
      }

      logStructureBeforeUpload(); // Log para verificar la estructura antes de subir
      updateFoldersWithFiles(root, filesArray); // Organiza la estructura final

      this.$emit("uploadFolders", root);
      this.$emit("uploadFiles", filesArray);
    };

    const traverseFileTree = async (item, path = "") => {
      if (item.isDirectory) {
        await processDirectory(item, path);
      } else if (item.isFile) {
        await processFile(item, path);
      }
    };

    const processDirectory = async (item, path) => {
      const dirReader = item.createReader();
      const readEntries = () =>
          new Promise((resolve) => dirReader.readEntries(resolve));
      let entries;

      do {
        entries = await readEntries();
        addToHierarchy(root, `${path}/${item.name}`);
        console.log(`Procesando directorio: ${path}/${item.name}`);
        await Promise.all(
            entries.map((entry) =>
                traverseFileTree(entry, `${path}/${item.name}`)
            )
        );
      } while (entries.length > 0);
    };

    const processFile = async (item, path) => {
      return new Promise((resolve) => {
        item.file((file) => {
          if (file.size > 0 && !file.name.startsWith(".")) {
            filesArray.push({ path: `${path}`, file });
            console.log(`Archivo agregado: ${file.name} en ${path}`);
          }
          resolve();
        });
      });
    };

    const addToHierarchy = (root, path) => {
      const parts = path.split("/").filter(Boolean);
      let currentLevel = root;

      parts.forEach((part) => {
        let existing = currentLevel.find(
            (item) => item.name === part && !item.isFile
        );
        if (!existing) {
          existing = { name: part, childs: [] };
          currentLevel.push(existing);
        }
        currentLevel = existing.childs;
      });
    };

    const logStructureBeforeUpload = () => {
      console.log("Estructura de carpetas:");
      console.log(JSON.stringify(root, null, 2));
      console.log("Lista de archivos:");
      filesArray.forEach(({ path, file }) =>
          console.log(`Archivo: ${file.name}, Ruta: ${path}`)
      );
    };

    function updateFoldersWithFiles(folders, files) {
      function findAndInsert(folder, pathParts, file) {
        if (pathParts.length === 0) {
          if (!folder.files) {
            folder.files = [];
          }
          folder.files.push({ name: file.name });
          return;
        }

        const nextFolderName = pathParts.shift();
        let childFolder = folder.childs.find(
            (child) => child.name === nextFolderName
        );

        if (!childFolder) {
          childFolder = { name: nextFolderName, childs: [], files: [] };
          folder.childs.push(childFolder);
        }
        findAndInsert(childFolder, pathParts, file);
      }

      files.forEach(({ file, path }) => {
        const pathParts = path.split("/").filter(Boolean);
        if (pathParts.length > 0) {
          const rootFolderName = pathParts.shift();
          let rootFolder = folders.find((folder) => folder.name === rootFolderName);

          if (!rootFolder) {
            rootFolder = { name: rootFolderName, childs: [], files: [] };
            folders.push(rootFolder);
          }

          findAndInsert(rootFolder, pathParts, file);
        }
      });

      return folders;
    }
  },
};
</script>

<style lang="scss">
.dropzone-custom-content {
  border: 1px dashed var(--primary);
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  text-align: center;
}

.dropzone-drag-content {
  border: 2px dashed var(--primary);
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  text-align: center;
  opacity: 40%;
}

.dropzone-custom-title {
  margin-top: 0;
  color: var(--primary)
}

.filepond--list {
  display: none;
}
#dropzone .dz-preview {
  display: none;
}
</style>
