<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="editUser">
      <b-form
        class="mt-2 auth-create-form"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col class="mb-2">
            <b-avatar size="6rem" :src="user.avatar" />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group :label="$t('Nombre')" label-for="account-name">
              <b-form-input
                v-model="user.name"
                name="name"
                :placeholder="$t('Nombre')"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('Apellidos')" label-for="account-surname">
              <b-form-input
                v-model="user.surname"
                :placeholder="$t('Apellidos')"
                name="surname"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-group label="Email" label-for="account-e-mail">
                <b-form-input
                  v-model="user.email"
                  name="email"
                  placeholder="Email"
                  :disabled="true"
                />
              </b-form-group>
              <small ref="errorField" class="text-danger">{{
                errors[0]
              }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-button type="submit" variant="primary" class="mt-2 mr-1">
              {{ $t('Guardar') }}
            </b-button>
            <b-button
              hidden
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      user: this.generalData,
      required,
      email,
    };
  },
  computed: {
    ...mapGetters({
      currentLanguage: "languages/getCurrentLanguage",
    }),
  },
  async created() {
    this.user = this.generalData;
  },
  methods: {
    ...mapActions({
      edit: "users/edit",
    }),
    resetForm() {
      this.user = JSON.parse(JSON.stringify(this.generalData));
    },
    handleSubmit() {
      this.$refs.editUser.validate().then((success) => {
        if (success) {
          const { id } = this.user;
          this.edit({ id, user: this.user });
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
      });
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
