<template>
  <BaseModal
    :id="'shared'"
    :hide-footer="true"
    :size="'sm'"
    :title="$t('Compartir carpeta')"
  >
    <div>
      <b-button
        style="padding-top: 4px !important; padding-bottom: 4px !important"
        variant="primary"
        class="btn-block d-flex align-items-center justify-content-center"
        @click="shareHandler('whatsapp')"
      >
        <WhatsappIcon
          :color="'white'"
          :w="30"
          :h="30"
        />
        <span>
          Whatsapp
        </span>
      </b-button>
      <b-button
        type="submit"
        variant="primary"
        class="btn-block d-flex align-items-center justify-content-center"
        @click="shareHandler('email')"
      >
        <feather-icon
          icon="MailIcon"
          size="20"
          class="mr-50"
        />
        <span>
          Email
        </span>
      </b-button>
      <b-button
        variant="primary"
        class="btn-block d-flex align-items-center  justify-content-center"
        @click="shareHandler('copy')"
      >
        <feather-icon
          icon="CopyIcon"
          size="20"
          class="mr-50"
        />
        <span>
          {{ $t('Copiar enlace') }}
        </span>
      </b-button>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/ui/modal/BaseModal.vue'
import WhatsappIcon from '@/assets/icons/WhatsappIcon.vue'
import { getIp } from '@/shared/services/ip-service'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BaseModal,
    WhatsappIcon,
  },
  props: {
    folderHash: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      share: 'folders/share',
    }),
    async shareHandler(type) {
      const ip = await getIp()
      const hash = this.folderHash
      if (!this.user) {
        await this.share({ hash, ip, type })
      }

      if (type === 'whatsapp') {
        this.shareOnWhatsApp(hash)
      }
      if (type === 'email') {
        this.shareByEmail(hash)
      }
      if (type === 'copy') {
        this.copyLink(hash)
      }
    },
    copyLink(hash) {
      const url = `${window.origin}/carpetas/${hash}`
      const el = document.createElement('textarea')
      el.value = url
      document.body.appendChild(el)
      el.select()
      navigator.clipboard.writeText(el.value)
      document.body.removeChild(el)
      this.$store.commit('notifications', {
        title: this.$t('Enlace copiado.'),
        variant: 'success',
      })
      this.$store.commit('setShowNotifications', true, { root: true })
    },
    shareOnWhatsApp(hash) {
      const url = `${window.origin}/carpetas/${hash}`
      console.log(url)
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(url)}`
      window.open(whatsappUrl, '_blank')
    },
    shareByEmail(hash) {
      const url = `${window.origin}/carpetas/${hash}`
      const subject = this.$t('Compartir carpeta GH Induction')
      const body = `${url}`
      const mailtoUrl = `mailto:?subject=${encodeURIComponent(
        subject,
      )}&body=${encodeURIComponent(body)}`
      window.location.href = mailtoUrl
    },
  },
}
</script>
