<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-row align-h="between" align-v="center" class="px-1 pt-2 pb-1">
            <b-col cols="auto">
              <h3 class="m-0">{{ $t("Carpetas") }}</h3>
            </b-col>
            <b-col lg="6" class="d-flex">
              <b-form-input
                v-model="search"
                :placeholder="$t('Buscar')"
                outlined
                class="mr-2"
              />
              <b-button
                @click="createModal = true"
                variant="primary"
                class="mr-1 text-nowrap"
              >
                + {{ $t("Nueva carpeta") }}
              </b-button>
            </b-col>
          </b-row>
          <b-overlay
            :show="loading"
            variant="white"
            spinner-variant="primary"
            rounded="sm"
          >
            <b-table hover responsive :items="items" :fields="columns">
              <template #cell(name)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <feather-icon icon="FolderIcon" />
                  </div>
                  <span class="ml-1">
                    <b-link
                      v-if="!rowsEditionEnabled[data.index]"
                      :to="{ name: 'viewFolder', params: { id: data.item.id } }"
                      class="link"
                    >
                      {{ data.item.name }}
                    </b-link>
                  </span>
                  <b-form-input
                    v-if="rowsEditionEnabled[data.index]"
                    v-model="data.item.name"
                  />
                </div>
              </template>
              <template #cell(password)="data">
                <span v-if="!rowsEditionEnabled[data.index]" class="ml-1">
                  {{ data.item.password }}
                </span>
                <b-input-group v-if="rowsEditionEnabled[data.index]">
                  <b-form-input maxLength="6" v-model="data.item.password" />
                  <small class="d-flex justify-content-end align-items-center mx-50">
                    {{ data.item.password.length || 0 }}/6
                  </small>
                </b-input-group>
              </template>
              <!-- Columna de Switch de Download -->
              <template #cell(download)="data">
                <b-form-checkbox
                    v-model="data.item.download"
                    :value="1"
                    :unchecked-value="0"
                    switch
                    @change="toggleDownload(data.item)"
                >
                </b-form-checkbox>
              </template>
              <template #cell(actions)="data">
                <div v-if="!rowsEditionEnabled[data.index]" class="d-flex">
                  <span
                    @click="generateQrModalHandler(data.item)"
                    class="d-inline-block px-50"
                  >
                    <QrIcon style="height: 20px; width: 20px"></QrIcon>
                  </span>
                  <b-link
                    :to="{ name: 'viewFolder', params: { id: data.item.id } }"
                    class="d-inline-block px-50 text-dark"
                  >
                    <FolderOpen style="height: 15px; width: 15px"></FolderOpen>
                  </b-link>
                  <b-link
                    @click="handleEditIconClick(data)"
                    class="d-inline-block px-50 text-dark"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-link>
                  <span
                    class="d-inline-block px-50 text-danger"
                    @click="deleteFolder(data.item.id, data.item.name)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </span>
                  <span
                    @click="sharedHandler(data.item)"
                    class="d-inline-block px-50"
                  >
                    <feather-icon icon="Share2Icon" />
                  </span>
                </div>
                <div
                  v-if="rowsEditionEnabled[data.index]"
                  class="d-flex justify-content-end"
                >
                  <span
                    class="d-inline-block text-blue"
                    @click="saveLine(data.item, data.index)"
                  >
                    <feather-icon icon="SaveIcon" size="18" />
                  </span>
                </div>
              </template>
            </b-table>
          </b-overlay>
          <div
            v-if="totalItems > pageLength"
            class="d-flex align-items-center justify-content-between mb-1 px-2"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ $t("Por página") }}: </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ $t("Total") }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <CreateFolderModal v-model="createModal" @created="createdFolder" />
    <SharedModal :folderHash="sharedHash"></SharedModal>
    <GenerateQrModal :folderId="generateQrFolderId"></GenerateQrModal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import QrIcon from "@/assets/icons/QrIcon.vue";
import CreateFolderModal from "@/components/folders/modal/CreateFolderModal.vue";
import SharedModal from "@/components/shared/modal/SharedModal.vue";
import GenerateQrModal from "@/components/folders/modal/GenerateQrModal.vue";
import FolderOpen from "@/assets/icons/FolderOpen.vue";

export default {
  data() {
    return {
      createModal: false,
      search: "",
      currentPage: 1,
      pageLength: 10,
      rowsEditionEnabled: [],
      pages: ["10", "25", "50"],
      columns: [
        {
          label: this.$t("Nombre"),
          key: "name",
          thStyle: { "min-width": "280px" },
        },
        {
          label: this.$t("Contraseña"),
          key: "password",
          thStyle: { "min-width": "250px" },
        },
        {
          label: this.$t("Descargable"),
          key: "download",
          thStyle: { "min-width": "30px" },
        },
        {
          label: this.$t("Acciones"),
          key: "actions",
          thClass: "text-center",
          thStyle: { width: "110px" },
        },
      ],
      loading: false,
      sharedHash: "",
      generateQrFolderId: "",
    };
  },
  components: {
    QrIcon,
    CreateFolderModal,
    SharedModal,
    GenerateQrModal,
    FolderOpen,
  },
  computed: {
    ...mapGetters({
      items: "folders/getItems",
      totalItems: "folders/getTotalItems",
    }),
  },
  watch: {
    search() {
      this.handleSearch()
    }
  },
  methods: {
    ...mapActions({
      list: "folders/getListFolders",
      edit: "folders/edit",
      delete: "folders/delete",
    }),
    handleSearch() {
      this.currentPage = 1;
      this.chargeData(false);
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData(true);
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData(true);
    },
    async chargeData(loading) {
      await this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.search,
        loading
      });
    },
    async createdFolder() {
      await this.chargeData(true);
      this.createModal = false;
    },
    async deleteFolder(id, name) {
      this.$bvModal
        .msgBoxConfirm(this.$t("EliminarConfirm", { nombre: name }), {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: this.$t('Eliminar'),
          cancelTitle: this.$t('Cancelar'),
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then(async (value) => {
          if (value === true) {
            await this.delete(id);
            await this.chargeData(true);
          }
        });
    },
    handleEditIconClick(row) {
      Vue.set(this.rowsEditionEnabled, row.index, true);
    },
    async saveLine(folderToUpdate, rowIndex) {
      const { name, password } = folderToUpdate;

      this.loading = true;

      await this.edit({
        id: folderToUpdate.id,
        folder: {
          name,
          password,
        },
      });

      this.loading = false;
      Vue.set(this.rowsEditionEnabled, rowIndex, false);
    },
    sharedHandler(folder) {
      this.sharedHash = folder.hash;
      this.$bvModal.show("shared");
    },
    generateQrModalHandler(folder) {
      this.generateQrFolderId = folder.id;
      this.$bvModal.show("generate-qr");
    },
    async toggleDownload(folder) {
      this.loading = true;
      await this.edit({
        id: folder.id,
        folder: {
          download: folder.download, // Cambia el valor de download (1 o 0)
        },
      });
      this.loading = false;
    },
  },
  async mounted() {
    await this.chargeData(true);
  },
};
</script>
