import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/auth'

export default {
  login(email, password) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/login`, {
      email,
      password,
    })
  },
  refreshToken() {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/refresh`)
  },
  me() {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/me`)
  },
  recoverPassword(email, lang) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/password/email`, {
      email,
      lang
    })
  },
  resetPassword(email, password, password_confirmation, token) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/password/reset`, {
      email,
      password,
      password_confirmation,
      token,
    })
  },
}
