<template>
  <BaseModal
    :id="'generate-qr'"
    :hideFooter="true"
    :size="'sm'"
    :title="$t('Generar codigo QR')"
  >
    <div>
      <b-button
        @click="generateQrHandler('si')"
        variant="primary"
        class="btn-block"
      >
        {{ $t('Con contraseña') }}
      </b-button>
      <b-button
        @click="generateQrHandler('no')"
        type="submit"
        variant="primary"
        class="btn-block"
      >
        {{ $t('Sin contraseña') }}
      </b-button>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/ui/modal/BaseModal.vue";
import { mapActions } from "vuex";

export default {
  props: {
    folderId: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    BaseModal,
  },
  methods: {
    ...mapActions({
      generateQr: "folders/generateQr"
    }),
    async generateQrHandler(password) {
      await this.generateQr({ id: this.folderId, password })
      this.$bvModal.hide('generate-qr')
    },
  },
};
</script>
