import configApi from '../../api/config-api'
import i18n from '@/libs/i18n'

const state = {
  config: {},
  selectTypeCompany: [],
}

const mutations = {
  setConfig(state, val) {
    state.config = val
  },
  setSelectTypeCompany(_, val) {
    state.selectTypeCompany = val
  },
}

const actions = {
  async getConfig({ commit }) {
    commit('app/loading', true, { root: true })
    await configApi.getConfig()
      .then(
        response => {
          commit('setConfig', response.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('Error al obtener la información.'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  setSelectTypeCompany({ commit }, companyTypes) {
    commit('setSelectTypeCompany', companyTypes)
  },
  async getSelectTypeCompany({ commit }) {
    commit('app/loading', true, { root: true })
    try {
      const response = await configApi.getSelectTypeCompany()
      commit('setSelectTypeCompany', JSON.parse(response.data.tipes))
      commit('app/loading', false, { root: true })
    } catch (error) {
      console.error(error)
      commit('app/loading', false, { root: true })
      commit('notifications', { title: i18n.t('Error al obtener la información.'), variant: 'danger' }, { root: true })
      commit('setShowNotifications', true, { root: true })
    }
  },
  edit({ commit }, { config }) {
    commit('app/loading', true, { root: true })
    configApi.edit(config)
      .then(
        () => {
          commit('notifications', { title: i18n.t('Información actualizada con éxito.'), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('Error al actualizar.'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  editSelectTypeCompany({ commit }, tipes) {
    commit('app/loading', true, { root: true })
    configApi.editSelectTypeCompany({ tipes })
      .then(
        () => {
          commit('notifications', { title: i18n.t('Información actualizada con éxito.'), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('Error al actualizar.'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getConfig: state => state.config,
  getSelectTypeCompany: state => state.selectTypeCompany,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
