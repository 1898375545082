import logsApi from '@/api/logs-api'
import DownloadService from '@/shared/services/download-service'
import i18n from '@/libs/i18n'

const actions = {
  async downloadLogs({ commit }) {
    commit('app/loading', true, { root: true })
    logsApi.downloadExcel()
      .then(response => {
        DownloadService.resolveAndDownloadBlob(response.data, 'Logs', 'xlsx')
      })
      .catch(() => {
        commit(
          'notifications',
          { title: i18n.t('Error al descargar el archivo.'), variant: 'danger' },
          { root: true },
        )
      })
      .finally(() => {
        commit('app/loading', false, { root: true })
      })
  },
}

export default {
  namespaced: true,
  actions,
}
