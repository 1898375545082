<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button
            variant="white"
            class="btn-icon rounded-circle mr-50 text-danger bg-white"
            style="padding: 0.5px"
          >
            <feather-icon
              icon="ArrowLeftCircleIcon"
              size="35"
            />
          </b-button>
        </span>
        {{ $t('Detalles usuario') }}
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <UserForm
        ref="user-form"
        v-model="user"
        :mode="'edit'"
      />
      <b-col cols="12 text-right">
        <b-button
          variant="primary"
          class="mt-2 mr-1"
          @click="handleSubmit"
        >
          {{ $t('Enviar') }}
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserForm from '@/components/users/form/UserForm.vue'

export default {
  components: {
    UserForm,
  },
  data() {
    return {
      id: this.$route.params.id,
      user: {},
    }
  },
  computed: {
    ...mapGetters({
      userToEdit: 'users/getUser',
      currentUser: 'auth/getUser',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    userForm() {
      return this.$refs['user-form']
    },
  },
  methods: {
    ...mapActions({
      edit: 'users/edit',
      getUser: 'users/getUser',
    }),
    setData() {
      console.log(this.userToEdit)
      this.user = this.userToEdit
      // if (this.user.avatar !== undefined && this.user.avatar !== null && this.user.avatar) {
      //   const avatar = {
      //     name: 'Avatar',
      //     url: this.user.avatar,
      //     urlDelete: `/users/delete_image/${this.user.id}`,
      //   }
      //   this.files = [avatar]
      // }
      // if (this.user.roles) {
      //   const role = this.user.roles[0]
      //   role.name_translation = role.display_name[this.currentLanguage]
      //   this.role = role
      // }
      // if (this.user.active === 1 || this.user.active === true) {
      //   this.active = true
      // }
    },
    async handleSubmit() {
      const success = await this.userForm.validateForm()

      if (!success) {
        this.$refs.errorField.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })

        return
      }

      this.edit({ id: this.id, user: this.user })
    },
    // createFormData() {
    //   const data = this.$refs.images.getFormData()
    //   if (this.role) {
    //     data.append('role', this.role.name)
    //   }
    //   data.append('name', this.user.name)
    //   data.append('surname', this.user.surname)
    //   data.append('email', this.user.email)

    //   if (this.active) {
    //     data.append('active', 1)
    //   } else {
    //     data.append('active', 0)
    //   }

    //   if (this.changePassword === true) {
    //     data.append('oldPassword', this.user.oldPassword)
    //     data.append('newPassword', this.user.newPassword)
    //     data.append('newPassword_confirmation', this.user.newPassword_confirmation)
    //   }

    //   return data
    // },
  },
  async created() {
    await this.getUser(this.id)
    await this.setData()
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
