<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="background-custom"
    :style="`background-image: url('${imgUrl}');` "
  >
    <b-container class="auth-wrapper-3">
      <b-row
        class="justify-content-center middle"
        align-v="center"
      >
        <b-col
          lg="10"
          class="d-flex auth-bg px-2 p-lg-5 background-white"
        >
          <b-col
            sm="12"
            md="6"
            lg="6"
            class="px-xl-2"
          >
            <b-card-title
              title-tag="h1"
              class="font-weight-bolder mb-1 text-white"
            >
              {{ $t('CambiarContraseña') }}
            </b-card-title>
            <div style="width: 25%">
              <hr class="linea">
            </div>

          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            class="px-xl-2 mx-auto"
          >
            <!-- form -->
            <validation-observer ref="reset-password-form">
              <b-form
                class="mt-2"
                @submit.prevent="handleSubmitResetPassword"
                @keydown.enter="handleSubmitResetPassword"
              >
                <b-container class="pl-3 pr-3">
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Password"
                          rules="required"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <b-form-input
                              id="login-password"
                              v-model="password"
                              :state="errors.length > 0 ? false:null"
                              class="form-control-merge"
                              :type="passwordFieldType"
                              name="login-password"
                              placeholder="············"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                class="cursor-pointer"
                                :icon="passwordToggleIcon"
                                @click="togglePasswordVisibility"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('RepitaContraseña')"
                          rules="required"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <b-form-input
                              id="login-confirmPassword"
                              v-model="confirmPassword"
                              :state="errors.length > 0 ? false:null"
                              class="form-control-merge"
                              :type="passwordFieldType"
                              name="login-password"
                              placeholder="············"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                class="cursor-pointer"
                                :icon="passwordToggleIcon"
                                @click="togglePasswordVisibility"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col>
                      <b-button
                        type="submit"
                        variant="primary"
                        class="mt-2 mr-1 text-uppercase"
                      >
                        {{ $t('Enviar') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import backgroundImage from '@/assets/images/pages/login/login.png'

export default {
  mixins: [togglePasswordVisibility],
  data() {
    return {
      token: this.$route.params.token,
      email: this.$route.params.email,
      password: '',
      confirmPassword: '',
      imgUrl: backgroundImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions({
      resetPassword: 'auth/resetPassword',
    }),
    async handleSubmitResetPassword() {
      const valid = await this.$refs['reset-password-form'].validate()
      if (!valid) {
        this.$refs.errorField.scrollIntoView({
          behavior: 'smooth',
        block: 'end', 
        });
        return
      }

      this.resetPassword({
        email: this.email,
        password: this.password,
        password_confirmation: this.confirmPassword,
        token: this.token,
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
